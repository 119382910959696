<template>
  <div class="detailnews">
    <Bscroll
      class="scroll"
      ref="scroll"
    >
      <div
        v-for="(item,index) in newslist"
        :key="index"
        @click="newsdetails(item.id)"
      >
        <div class="msitem">
          <div class="msitem-left">
            <h4>{{item.title}}</h4>
            <span class="mscontent small-size">{{item.description}}</span>
          </div>
          <div class="msitem-right">
            <img
              v-lazy="item.titleImg"
              alt=""
            >
          </div>
        </div>
      </div>
    </Bscroll>
  </div>
</template>

<script>
import Bscroll from '@/components/content/scroll/BScroll'
import { news } from '@/network/service'
import { newsdate } from '@/common/utils'
export default {
  data () {
    return {
      newslist: []
    }
  },
  components: {
    Bscroll
  },
  mounted () {
    this.newsPost()
  },
  methods: {
    async newsPost () {
      let date = newsdate()
      let res = await news({
        begin_date: date[0],
        end_date: date[1]
      })
      this.newslist = res.data.content
      this.newslist.forEach(el => {
        el.titleImg = 'http://weixin.nhdyyy.cn/weixin/handHospital' + el.titleImg
      })
      this.$nextTick(() => {
        this.$refs.scroll.refresh()
      })
    },
    newsdetails (id) {
      this.$router.push({ path: '/news', query: { id } })
    }
  },
}
</script>

<style lang="less" scoped>
.detailnews {
  .scroll {
    height: 100vh;
    overflow: hidden;
    .msitem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid @lineColor;
      padding: 0.5rem;
      box-sizing: border-box;
      .msitem-left {
        display: flex;
        flex-direction: column;
        flex: 0.55;
        h4 {
          // font-size: 0.8rem;
          -webkit-line-clamp: 2;
        }
        .mscontent {
          // text-indent: 2rem;
          -webkit-line-clamp: 2;
          padding-top: 0.5rem;
        }
        h4,
        .mscontent {
          overflow: hidden;
          text-overflow: ellipsis;
          word-break: break-all;
          display: -webkit-box;
          -webkit-box-orient: vertical;
        }
      }
      .msitem-right {
        flex: 0.4;
        height: 5.75rem;
        width: 8rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 0.3rem;
        }
      }
    }
  }
}
</style>